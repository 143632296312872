<template>
    <svg @click="$emit('click')"
         viewBox="0 0 20 20"
         class="h-4 w-4 mx-1 fill-current cursor-pointer">
        <path d="M10,0.75L15.5,6H12v13H8V6H4.5L10,0.75z"/>
    </svg>
</template>

<script>
    export default {
        name: "ArrowUpButton",
    }
</script>

<style scoped>

</style>