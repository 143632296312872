<template>
    <div class="mb-3 flex items-center">
        <div class="mr-3 w-24">{{ title }}</div>
        <div class="w-full border-b border-b-2 py-2 border-grey-500">
            <form enctype="multipart/form-data" novalidate>
                <input class="appearance-none
                          w-full mr-3 py-1 px-2
                          bg-transparent text-gray-700 border-none leading-tight focus:outline-none"
                       @change="filesChange($event.target.files)"
                       type="file"
                       accept="image/*">
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "InlineImageUploadInput",
        props: {
            title: String,
            value: String,
        },
        methods: {
            filesChange(fileList) {
                const formData = new FormData();

                if (!fileList.length) return;

                formData.append('file', fileList[0], fileList[0].name);

                // save it
                this.uploadImage(formData)
            },
            uploadImage(formData) {
                const url = this.$apiURI + '/images';
                return this.$http.post(url, formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.jwt
                    }
                })
                    // get data
                    .then(x => this.$emit('input', x.data));
            }
        }
    }
</script>

<style scoped>

</style>