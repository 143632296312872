<template>
    <div class="px-6 mt-4
                    md:mt-8">
        <img :src="src"
             :alt="alt"/>
        <div v-if="caption" class="image-block-caption pt-1 text-center text-gray-700" v-html="preprocessText(caption)">
        </div>
    </div>
</template>

<script>
    import htmlUtils from '@/util/html_utils';

    export default {
        name: "ImageBlock",
        props: {
            src: String,
            alt: String,
            caption: String
        },
        mixins: [htmlUtils]
    }
</script>

<style scoped>
    .image-block-caption >>> a {
        @apply underline text-custom-blue
    }
</style>