<template>
    <div class="mb-3 flex items-center">
        <div class="mr-3 w-24">{{ title }}</div>
        <div :class="disabled ? 'border-grey-500' : 'border-teal-500'"
             class="w-full py-2">
            <input @change="change"
                   :disabled="disabled"
                   type="checkbox"
                   :aria-label="title"
                   :checked="value">
        </div>
    </div>
</template>

<script>
    export default {
        name: "InlineBooleanInput",
        props: {
            title: String,
            value: Boolean,
            disabled: Boolean
        },
        methods: {
            change(e) {
                this.$emit("input", e.target.checked);
            }
        }
    }
</script>

<style scoped>

</style>