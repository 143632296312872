<template>
    <div>
        <main role="main">
            <div class="md:py-5 xl:py-8
                    bg-gray-200 mb-3
                    md:mb-0 md:bg-gray-100
                    xl:max-w-screen-xl xl:mx-auto ">
                <div class="flex py-2 px-4 justify-between items-center border-b border-gray-300
                        md:justify-center md:border-none">
                    <router-link class="w-1/3 md:hidden" to="/">
                        <img class="mx-right md:hidden h-12" alt="Faimyxstitch logo"
                             src="@/assets/navbar/logo_mobile.png">
                    </router-link>
                    <router-link class="w-1/3 md:w-full" to="/">
                        <img class="mx-auto md:hidden h-10" alt="Faimyxstitch logo"
                             src="@/assets/navbar/logo_mobile_text.png">
                        <img class="hidden md:block md:h-32 md:mx-auto lg:h-40" alt="Faimyxstitch logo"
                             src="@/assets/navbar/logo_desktop.png">
                    </router-link>

                    <button @click="isNavbarOpen = !isNavbarOpen" type="button"
                            class="block w-1/3 ml-auto pr-2 text-2xl text-gray-800 focus:outline-none
                                                   md:hidden">
                        <svg class="ml-auto h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path v-if="isNavbarOpen" fill-rule="evenodd"
                                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                            <path v-if="!isNavbarOpen" fill-rule="evenodd"
                                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                        </svg>
                    </button>
                </div>

                <div :class="isNavbarOpen ? 'block' : 'hidden'"
                     class="px-2 pt-2 pb-4
                            md:flex md:justify-center">
                    <NavBarLink url="/admin/posts">All Posts</NavBarLink>
                    <NavBarLink url="/admin/posts/create">Create Post</NavBarLink>
                </div>
            </div>

            <router-view></router-view>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import NavBarLink from "@/components/navbar/NavBarLink";
    import Footer from "@/components/Footer";

    export default {
        name: "Admin",
        data() {
            return {
                isNavbarOpen: false,
            }
        },
        components: {
            NavBarLink, Footer
        }
    }
</script>

<style scoped>

</style>