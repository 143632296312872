<template>
    <div class="mb-3 flex items-center">
        <div class="mr-3 w-24">{{ title }}</div>
        <div :class="disabled ? 'border-grey-500' : 'border-teal-500'"
             class=" border-b border-b-2 py-2">
            <select @change="change"
                    :disabled="disabled"
                    :aria-label="title"
                    class="appearance-none
                          w-full mr-3 py-1 px-2
                          bg-transparent text-gray-700 border-none leading-tight focus:outline-none">
                <option value="">Select color</option>
                <option v-for="(option, index) in options"
                        :key="index"
                        :value="option"
                        :selected="selectedOption(option)">
                    {{ option }}
                </option>
            </select>
        </div>
        <div :class="'bg-' + value"
                class="w-6 h-6">

        </div>
    </div>
</template>

<script>
    export default {
        name: "InlineColorSelect",
        props: {
            title: String,
            value: String,
            disabled: Boolean
        },
        data() {
            return {
                selected: null,
                options: [
                    'custom-blue',
                    'custom-light-blue',
                    'custom-green',
                    'custom-light-green',
                    'custom-pink',
                    'custom-dark-pink',
                    'custom-yellow-pink',
                    'custom-yellow',
                    'custom-orange',
                    'custom-red',
                    'custom-red-pink',
                    'custom-violet'
                ]
            }
        },
        methods: {
            selectedOption(option) {
                if (this.value) {
                    return option === this.value;
                }
                return false;
            },
            change(e) {
                const selectedCode = e.target.value;
                const option = this.options.find((option) => {
                    return selectedCode === option;
                });
                this.$emit("input", option);
            }
        }
    }
</script>

<style scoped>

</style>