export default {
    methods: {
        preprocessText: function (text) {
            if (text) {
                return text.replace(/\[([^[\]()]+)]\(([^[\]()]+)\)/g,
                    function (match, linkTitle, linkUrl) {
                        return '<a href="' + linkUrl + '">' + linkTitle + '</a>';
                    });
            } else {
                return text
            }
        }
    }
}