<template>
    <svg @click="$emit('click')"
         viewBox="0 0 24 24"
         class="h-6 w-6 mx-1 fill-current cursor-pointer">
        <path fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
    </svg>
</template>

<script>
    export default {
        name: "CrossButton",
    }
</script>

<style scoped>

</style>