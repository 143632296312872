<template>
    <div class="flex justify-center">
        <a :href="url">
            <button :class="'bg-' + color"
                    class="shadow underline uppercase hover:bg-opacity-75 text-normal text-white font-bold tracking-wide py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                    type="submit">
                {{ text }}
            </button>
        </a>
    </div>
</template>

<script>
    export default {
        name: "ButtonLink",
        props: {
            text: String,
            url: String,
            color: String
        }
    }
</script>

<style scoped>

</style>