<template>
    <div class="w-full mx-auto
                md:max-w-screen-md md:flex md:flex-wrap md:justify-center">

        <div v-for="(post, index) in posts.items" :key="index"
             class="bg-white mx-3 my-3 px-3 py-3 rounded-lg shadow bg-white
                    md:w-1/2
                    xl:w-1/3">
            <div class="flex">
                <div class="w-2/3 xl:w-5/6">
                    {{ post.name }}
                </div>
                <div v-if="post.online" class="w-1/3 xl:w-1/6 flex justify-end items-center ml-auto pr-2">
                    <div class="w-3 h-3 ml-2 bg-custom-green rounded-full"></div>
                </div>
                <div v-if="!post.online" class="w-1/3 xl:w-1/6 flex justify-end items-center ml-auto pr-2">
                    <div class="w-3 h-3 ml-2 bg-custom-red rounded-full"></div>
                </div>
            </div>

            <router-link :to="'/admin/posts/edit/' + post.urlKey"
                         class="underline mr-2">Edit</router-link>
            <router-link :to="'/posts/' + post.urlKey"
                         class="underline mr-2">View</router-link>
        </div>
    </div>
</template>

<script>
    const axios = require('axios').default

    export default {
        name: "AllPosts",
        data() {
            return {
                posts: []
            }
        },
        mounted() {
            axios
                .get(this.$apiURI + '/posts', {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.jwt
                    }
                })
                .then(response => (this.posts = response.data))
        }
    }
</script>

<style scoped>

</style>