<template>
  <header>
    <div class="md:py-5 xl:py-8
                    bg-gray-200 mb-3
                    md:mb-0 md:bg-gray-100
                    xl:max-w-screen-xl xl:mx-auto ">
      <div class="flex py-2 px-4 justify-between items-center border-b border-gray-300
                        md:justify-center md:border-none">
        <router-link class="w-1/3 md:hidden" to="/">
          <img class="mx-right md:hidden h-12" alt="Faimyxstitch logo" src="@/assets/navbar/logo_mobile.png">
        </router-link>
        <router-link class="w-1/3 md:w-full" to="/">
          <img class="mx-auto md:hidden h-10" alt="Faimyxstitch logo"
               src="@/assets/navbar/logo_mobile_text.png">
          <img class="hidden md:block md:h-32 md:mx-auto lg:h-40" alt="Faimyxstitch logo"
               src="@/assets/navbar/logo_desktop.png">
        </router-link>

        <button @click="isNavbarOpen = !isNavbarOpen" type="button"
                class="block w-1/3 ml-auto pr-2 text-2xl text-gray-800 focus:outline-none
                                               md:hidden">
          <svg class="ml-auto h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isNavbarOpen" fill-rule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            <path v-if="!isNavbarOpen" fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
      </div>

      <div :class="isNavbarOpen ? 'block' : 'hidden'"
           class="
                        px-2 pt-2 pb-4
                        md:flex md:justify-center">
        <NavBarLink @click="closeMenu" to="/">Home</NavBarLink>

        <NavBarLink @click="closeMenu" to="/about">About</NavBarLink>

        <NavBarLink @click="closeMenu" to="/posts">Posts</NavBarLink>

        <NavBarDropdownLink :parent-link="patternsDropdown.parentLink"
                            :dropdown-links="patternsDropdown.dropdownLinks"/>

        <NavBarDropdownLink :parent-link="coursesDropdown.parentLink"
                            :dropdown-links="coursesDropdown.dropdownLinks"/>
      </div>
    </div>
  </header>
</template>

<script>
import NavBarLink from "@/components/navbar/NavBarLink";
import NavBarDropdownLink from "@/components/navbar/NavBarDropdownLink";

export default {
  methods: {
    closeMenu: function () {
      this.isNavbarOpen = false
    }
  },
  data() {
    return {
      isNavbarOpen: false,
      isDropdownOpen: false,
      patternsDropdown: {
        parentLink: {
          title: "Patterns",
          url: "https://www.etsy.com/shop/FaimyCrossStitch"
        },
        dropdownLinks: [
          {
            title: "For beginners",
            url: "https://www.etsy.com/shop/FaimyCrossStitch?ref=seller-platform-mcnav&section_id=18528242"
          },
          {
            title: "Cities",
            url: "https://www.etsy.com/shop/FaimyCrossStitch?ref=seller-platform-mcnav&section_id=18528204"
          },
          {
            title: "Florals",
            url: "https://www.etsy.com/shop/FaimyCrossStitch?ref=seller-platform-mcnav&section_id=18532189"
          },

          {
            title: "For experienced",
            url: "https://www.etsy.com/shop/FaimyCrossStitch?ref=seller-platform-mcnav&section_id=27736241"
          }
        ]
      },
      coursesDropdown: {
        parentLink: {
          title: "Courses",
          url: "https://faimyxstitch.thinkific.com"
        },
        dropdownLinks: [
          {
            title: "Upcycling with Embroidery",
            url: "https://www.domestika.org/en/courses/1395-upcycling-with-embroidery/faimyxstitch"
          },
          {
            title: "Stitches and practice",
            url: "https://faimyxstitch.thinkific.com/courses/hand-embroidery-stitches-and-practice"
          },
          {
            title: "Draw your city",
            url: "https://faimyxstitch.thinkific.com/courses/how-to-draw-and-embroider-your-city"
          }
        ]
      }

    }
  },
  components: {
    NavBarDropdownLink,
    NavBarLink
  }
}
</script>

<style scoped>

</style>