<template>
    <div @mouseenter="isDropdownOpen = true" @mouseleave="isDropdownOpen = false" class="md:relative">
        <NavBarLink :to="parentLink.to" :url="parentLink.url">{{parentLink.title}}</NavBarLink>
        <div :class="isDropdownOpen ? 'md:block' : 'md:hidden'"
             class="md:bg-gray-200 md:absolute md:inset-x-0 md:w-64 md:rounded-lg md:shadow-lg md:z-50">
            <div v-for="(link, linkIndex) in dropdownLinks"
                 :key="linkIndex">
                <NavBarLink :url="link.url" :to="link.to" secondary>{{link.title}}
                </NavBarLink>
            </div>
        </div>
    </div>
</template>

<script>
    import NavBarLink from "@/components/navbar/NavBarLink";

    export default {
        name: "NavBarDropdownLink",
        components: {NavBarLink},
        props: {
            parentLink: Object,
            dropdownLinks: Array
        },
        data() {
            return {
                isDropdownOpen: false
            }
        },
    }
</script>

<style scoped>

</style>