<template>
    <div class="md:max-w-screen-md mx-auto">
        <div v-for="(block, index) in post.blocks" :key="index">
            <PostTitle v-if="block.__typename === 'title'"
                       :title="block.title"
                       :color="block.titleColor"/>
            <ImageBlock v-if="block.__typename === 'image'"
                        :src="block.src"
                        :alt="block.alt"
                        :caption="block.caption"/>
            <YoutubeVideoBlock v-if="block.__typename === 'youtube-video'"
                               :video-id="block.videoId"
                               :caption="block.caption"/>
            <List v-if="block.__typename === 'list'"
                  :title="block.title"
                  :title-color="block.titleColor"
                  :markers-color="block.markersColor"
                  :items="block.items"/>
            <ButtonLink v-if="block.__typename === 'button-link'"
                        :text="block.text"
                        :url="block.url"
                        :color="block.color"/>
            <TextBlock v-if="block.__typename === 'text'"
                       :text="block.text"
                       :title="block.title"
                       :title-color="block.titleColor"
                       :bg-color="block.bgColor"/>
        </div>
    </div>
</template>

<script>
    import PostTitle from "@/components/post/PostTitle";
    import List from "@/components/post/List";
    import TextBlock from "@/components/post/TextBlock";
    import ImageBlock from "@/components/post/ImageBlock";
    import ButtonLink from "@/components/post/ButtonLink";
    import YoutubeVideoBlock from "@/components/post/YoutubeVideoBlock";

    const axios = require('axios').default
    const apiUri = process.env.NODE_ENV === 'production' ? 'https://faimyxstitch.com/api' : 'http://localhost:8181'

    function getPost(postId, callback) {
        axios
            .get(apiUri + '/posts/' + postId, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.jwt
                }
            })
            .then((response) => {
                callback(response.data)
            })
    }

    export default {
        name: "Post",
        components: {
            YoutubeVideoBlock,
            ButtonLink,
            ImageBlock,
            TextBlock,
            List,
            PostTitle
        },
        props: {
            postId: String
        },
        data() {
            return {
                post: {
                    meta: {},
                    blocks: []
                }
            }
        },
        created() {
            this.fetchData()
        },
        watch: {
            '$route': 'fetchData'
        },
        methods: {
            fetchData() {
                getPost(this.postId, (post) => {
                    this.post = post;
                });
            }
        },
        metaInfo() {
            const localPost = this.post

            if (!localPost.meta) {
                return {}
            }

            const metaTitle = localPost.meta.title
            return {
                title: metaTitle ? metaTitle : '',
                meta: [
                    {name: 'description', content: localPost.meta.description},
                    {name: 'keywords', content: localPost.meta.keywords}
                ]
            }
        }
    }
</script>

<style scoped>

</style>