<template>
  <div class="px-6 my-6
                md:mt-8">
        <span v-if="title"
              :class="'bg-' + titleColor + ' ' + 'shadow-text-'+ titleColor"
              class="ml-2 font-thin text-white text-2xl whitespace-pre-line
                 md:whitespace-no-wrap
                 lg:text-3xl">
            {{ title }}
        </span>
    <ul>
      <li v-for="(item, itemIndex) in items" :key="itemIndex" class="mt-6">
        <div
            :class="'bg-' + markersColor"
            class="w-3 h-3 rounded-full inline-block mr-2">
        </div>
        <div class="inline text-lg">
          <div class="inline" v-for="(node, nodeIndex) in item.nodes" :key="itemIndex + '-' + nodeIndex">
            <div
                v-if="node.__typename === 'list-item-text'"
                class="inline list-text-node whitespace-pre-wrap"
                v-html="preprocessText(node.text)">
            </div>
            <div
                v-if="node.__typename === 'list-item-image'"
                class="mx-6 mt-5 my-4">
              <img :src="node.src" :alt="node.alt">
              <div v-if="node.caption" class="image-block-caption pt-1 text-center text-gray-700"
                   v-html="preprocessText(node.caption)">
              </div>
            </div>
            <div
                v-if="node.__typename === 'list-item-youtube-video'"
                class="mx-6 mt-5 mb-4">
              <div class="videoWrapper relative mx-0 mt-3">
                <iframe class="absolute inset-0 w-full h-full"
                        width="560" height="315"
                        :src="embeddedYoutubeLink(node.videoId)"
                        frameborder="0" allowfullscreen></iframe>
              </div>
              <div v-if="node.caption" class="image-block-caption pt-1 text-center text-gray-700"
                   v-html="preprocessText(node.caption)">
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import youtubeUtils from '@/util/youtube_utils';
import htmlUtils from '@/util/html_utils';

export default {
  name: "List",
  props: {
    title: String,
    titleColor: String,
    markersColor: String,
    items: Array
  },
  mixins: [htmlUtils, youtubeUtils]
}
</script>

<style scoped>
.image-block-caption >>> a {
  @apply underline text-custom-blue
}

.list-text-node >>> a {
  @apply underline text-custom-blue
}

.videoWrapper {
  padding-bottom: 56.25%; /* 16:9 */
}
</style>