<template>
  <div class="mt-4
                md:px-6 md:mt-8">
    <div class="videoWrapper relative mx-0 mt-3">
      <iframe class="absolute inset-0 w-full h-full"
              width="560" height="315"
              :src="embeddedYoutubeLink(videoId)"
              frameborder="0" allowfullscreen></iframe>
    </div>
    <div v-if="caption" class="image-block-caption text-lg pt-1 text-center text-gray-700" v-html="preprocessText(caption)">
    </div>
  </div>
</template>

<script>
import youtubeUtils from '@/util/youtube_utils';
import htmlUtils from '@/util/html_utils';

export default {
  name: "YoutubeVideoBlock",
  props: {
    videoId: String,
    caption: String
  },
  mixins: [htmlUtils, youtubeUtils]
}
</script>

<style scoped>
.image-block-caption >>> a {
  @apply underline text-custom-blue
}

.videoWrapper {
  padding-bottom: 56.25%; /* 16:9 */
}
</style>