<template>
  <div class="md:max-w-screen-md mx-auto">
    <div v-for="(post, postIndex) in posts" :key="postIndex"
         class="mt-4 border shadow  overflow-hidden bg-white mx-3
                md:mb-8">
      <router-link :to="'/posts/' + post.urlKey">
        <div class="relative h-40 flex items-center
                lg:h-64 lg:mb-1">
          <div class="z-0 h-full w-full">
            <img class="object-cover h-full w-full" :src="post.previewImage" :alt="post.name">
          </div>
          <div class="absolute z-10 h-full w-full">
            <div class="px-6 py-3
                        md:text-center md:h-full md:flex md:justify-center md:items-center">
              <div>
                <span :class="'bg-' + findTitleBlock(post).titleColor + ' shadow-text-' + findTitleBlock(post).titleColor"
                      class="text-white text-3xl whitespace-pre-line
                             lg:text-5xl">
                    {{ findTitleBlock(post).title }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </router-link>
      <div class="px-3 py-3 text-lg
                    md:px-6 md:pt-6 md:pb-3
                    post-description"
           v-html="preprocessText(post.description)">
      </div>

      <div class="pb-3
                  md:pb-6">
        <ButtonLink :color="findTitleBlock(post).titleColor"
                    text="Read more"
                    :url="'/posts/' + post.urlKey"/>
      </div>

    </div>
  </div>
</template>

<script>
import htmlUtils from "@/util/html_utils";
import ButtonLink from "@/components/post/ButtonLink";

const axios = require('axios').default
const apiUri = process.env.NODE_ENV === 'production' ? 'https://faimyxstitch.com/api' : 'http://localhost:8181'

function getPosts(postId, callback) {
  axios
      .get(apiUri + '/posts', {
        headers: {
          Authorization: 'Bearer ' + localStorage.jwt
        }
      })
      .then((response) => {
        callback(response.data.items)
      })
}

export default {
  name: "Posts",
  components: {ButtonLink},
  data() {
    return {
      posts: []
    }
  },
  created() {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  metaInfo() {
    return {
      title: 'Posts',
      meta: [
        {
          name: 'description', content: 'Faimyxstitch website about hand embroidery. City patterns, ' +
              'architectural embroideries, video tutorials and articles with embroidery tips.'
        },
        {
          name: 'keywords',
          content: 'hand embroidery, video tutorial, city embroidery pattern, diy project, ' +
              'craft ideas, architectural embroidery, hand embroidery tips, faimyxstitch, FaimyCross Stitch'
        }
      ]
    }
  },
  methods: {
    fetchData() {
      getPosts(this.postId, (posts) => {
        this.posts = posts;
      });
    },
    findTitleBlock(post) {
      let titleBlocks = post.blocks.filter(block => block.__typename === 'title')

      return (titleBlocks.length > 0) ? titleBlocks[0] : {
        title: post.name,
        titleColor: 'custom-green'
      }
    }
  },
  mixins: [htmlUtils]
}
</script>

<style scoped>
.post-description >>> a {
  @apply underline text-custom-blue
}
</style>