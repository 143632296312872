<template>
  <div v-if="!isPrerendering"
       class="rounded-lg bg-faimy-pink-2 mx-3 shadow-md overflow-hidden
                md:max-w-screen-md md:mx-auto md:mt-16 md:bg-faimy-pink-2">
    <div class="px-6 pt-3 pb-2
                    md:pt-10 md:text-center md:flex md:h-full md:justify-center md:items-center">
                <span class="bg-faimy-pink shadow-text-pink text-white text-3xl whitespace-pre-line
                             md:whitespace-no-wrap
                             lg:text-5xl">
                    Quick Tip
                </span>
    </div>
    <div class="px-4 mb-2">
                <span class="font-semibold tracking-wide text-lg">
                    {{ randomVideo.title }}
                </span>
    </div>
    <div class="videoWrapper relative mx-0 mt-3" id="kkk">
      <iframe class="absolute inset-0 w-full h-full"
              :width="randomVideo.width" :height="randomVideo.height"
              :src="embeddedYoutubeLink(randomVideo.id)"
              frameborder="0" allowfullscreen></iframe>
    </div>
    <div class="px-4 pt-3 pb-3">
                <span class="whitespace-pre-line">
                    {{ randomVideo.text }}
                </span>
    </div>
  </div>
</template>

<script>
import youtubeUtils from '@/util/youtube_utils';

export default {
  name: "QuickTipYoutube",
  data() {
    return {
      isPrerendering: true,
      videos: [
        {
          id: 'HrCgApDeOvE',
          width: 560,
          height: 315,
          title: 'How to choose fabric for embroidery',
          text: 'What fabric to use?\n' +
              'This is the first question that comes to your head if you want to learn embroidering.\n' +
              'I described all kinds of fabric I\'m using for my art in this video. All of them you can buy online and start your journey.\n'
        },
        {
          id: 'Yx_JUCdAf9Q',
          width: 560,
          height: 315,
          title: 'Needles for embroidery',
          text: 'Info about choice of needles\n' +
              'This is the second video in my series for beginners. If you enjoy it - subscribe to my channel to watch other videos.'
        },
        {
          id: 'kbMdsKX5si0',
          width: 560,
          height: 315,
          title: 'How to bind an embroidery hoop and why',
          text: 'Embroidery with good hoop and nice fabric tension is real happiness and satisfaction. Bad hoop can ruin this. You’ll constantly pull fabric to get it taut or will end up with loose thread.\n' +
              '\n' +
              'It\'s better to buy wooden hoops offline, to examine and check quality. Outer and inner hoops should be fit tightly. Look for hoop made from one piece of wood or bamboo. \n' +
              'Plastic hoops don\'t have these problems. They usually fit tight and have a lot of different colors. But plastic hoops without tightening screw doesn’t hold fabric as they should. You can use those for displaying embroidery when it’s finished.\n' +
              '\n' +
              'To sum up: look for hoop with tightening screw. If you’re looking for wooden hoop, check if the inner and outer hoop fit tightly! If you already have bad hoop - there\'s a way to fix it: bind it.\n' +
              '\n' +
              'To check this, tighten the screw and see if there holes between the hoops.'
        },
        {
          id: 'evkmnLOmR4g',
          width: 560,
          height: 315,
          title: 'How to choose embroidery hoop',
          text: 'Embroidery with good hoop and nice fabric tension is real happiness and satisfaction. Bad hoop can ruin this. You’ll constantly pull fabric to get it taut or will end up with loose thread.\n' +
              '\n' +
              'It\'s better to buy wooden hoops offline, to examine and check quality. Outer and inner hoops should be fit tightly. Look for hoop made from one piece of wood or bamboo. \n' +
              'Plastic hoops don\'t have these problems. They usually fit tight and have a lot of different colors. But plastic hoops without tightening screw doesn’t hold fabric as they should. You can use those for displaying embroidery when it’s finished.\n' +
              '\n' +
              'To sum up: look for hoop with tightening screw. If you’re looking for wooden hoop, check if the inner and outer hoop fit tightly!\n' +
              '\n' +
              'To check this, tighten the screw and see if there holes between the hoops.'
        },
        {
          id: 't1biOKI9zX4',
          width: 560,
          height: 315,
          title: 'How to embroider on a T-shirt with magic paper or water soluble stabilizer',
          text: 'On this video I\'m showing how you can use magic paper by DMC brand or water soluble stabilizer for embroidery on a stretchy fabric.\n' +
              '\n' +
              'If you\'re using non stretchy clothing like jeans or linen you can embroider without any stabilizer. However, if you\'re new to embroidery or chose difficult fabric it\'ll be a big help.\n' +
              '\n' +
              'Also water soluble fabric/paper can also be used for transfer. Trace or print design on a paper, stick it to your fabric and embroider.'
        },
        {
          id: 'B5aiqNLbM8c',
          width: 560,
          height: 315,
          title: 'How to back an embroidery without a hoop',
          text: 'If you don\'t want to leave your embroidery in a hoop here\'s one more way to back your piece.'
        },
        {
          id: '1tP6KoM6MEc',
          width: 560,
          height: 315,
          title: 'How many strands to use for hand embroidery',
          text: 'Honestly, I don\'t think there are rules like - don\'t make french knots with one strand or don\'t make satin stitch with 6 strands. Because all of this can look good and the final choice depends on your goals!'
        },
        {
          id: 'NvOXBNCyq78',
          width: 560,
          height: 315,
          title: 'How to hand embroider on a T-shirt',
          text: 'T-shirts are made from stretchy fabric. You\'re going to need stabilizer or interfacing.\n' +
              'There are two kinds to choose from\n' +
              '- woven ( it has the structure and you should place it exactly as the structure of your t-shirt fabric\n' +
              '- non-woven (it doesn\'t have a structure)\n' +
              'Also, they can be fusible, water soluble and tear away.\n' +
              'For fusible stabilizer: put it on the back of your t-shirt and iron it on.\n' +
              'For the water-soluble and tear away: pit it on the back of your t-shirt and stitch it on. \n' +
              '\n' +
              'After you embroidery your design you won\'t be able to remove fusible stabilizer. \n' +
              'But you can remove water soluble stabilizer with water and tear away the other kind. It\'ll stay only under your embroidered design. '
        },
        {
          id: 'k5Wfd_OeiS8',
          width: 560,
          height: 315,
          title: 'Two eyed needle - one more stitch tutorial',
          text: 'One more stitch for double eyes needle. You can thread two floss and change color wherever you want!\n' +
              'This stitch is harder than the first, be careful with the thread. It will often be tangled'
        },
        {
          id: 'hOHZTI4iHFs',
          width: 560,
          height: 315,
          title: 'Double eyed needle - stitch tutorial',
          text: 'You can thread two floss and change color wherever you want!\n' +
              'That\'s pretty fun and easy to make. '
        },
        {
          id: 'zCrfoGs86Q0',
          width: 560,
          height: 315,
          title: 'Thread for embroidery',
          text: 'Some options of thread for embroidery. \n' +
              '\n' +
              'This is the third video in my series for beginners. If you enjoy it - subscribe to my channel to watch other videos.'
        }
      ]
    }
  },
  computed: {
    randomVideo: function () {
      return this.videos[Math.floor(Math.random() * this.videos.length)];
    }
  },
  mounted() {
    this.isPrerendering = window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.isPrerendering
  },
  mixins: [youtubeUtils]
}
</script>

<style scoped>
.videoWrapper {
  padding-bottom: 56.25%; /* 16:9 */
}
</style>