<template>
    <div>
        <main role="main">
            <NavBar/>
            <router-view></router-view>
            <JoinNewsletter/>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import NavBar from "@/components/navbar/NavBar";
    import JoinNewsletter from "@/components/JoinNewsletter";

    export default {
        name: "StoreFront",
        components: {
            Footer,
            NavBar,
            JoinNewsletter
        },
        metaInfo: {
            titleTemplate: '%s | Faimyxstitch — Embroidery Artist'
        }
    }
</script>

<style scoped>

</style>