<template>
    <div class="px-8 pt-3 pb-2
                md:pt-10 md:text-center md:flex md:h-full md:justify-center md:items-center">
        <span :class="'bg-' + color + ' shadow-text-' + color"
              class="font-thin text-white text-3xl whitespace-pre-line
                     md:whitespace-no-wrap
                     lg:text-4xl">
            {{ title }}
        </span>
    </div>
</template>

<script>
    export default {
        name: "PostTitle",
        props: {
            title: String,
            color: String
        }
    }
</script>

<style scoped>

</style>