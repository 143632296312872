import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag';
import VueMeta from 'vue-meta';
import App from './App.vue'

import './assets/styles/main.css';

import Home from "@/components/pages/Home";
import Vuelidate from 'vuelidate'
import Post from "@/components/pages/Post";
import StoreFront from "@/components/apps/StoreFront";
import Admin from "@/components/apps/Admin";
import AllPosts from "@/components/pages/admin/AllPosts";
import EditPost from "@/components/pages/admin/EditPost";

import Axios from 'axios'
import Login from "@/components/pages/admin/Login";
import Toasted from 'vue-toasted';
import Posts from "@/components/pages/Posts";

Vue.prototype.$http = Axios;

Vue.use(Toasted)
Vue.use(Vuelidate)
Vue.use(VueRouter)
Vue.use(VueMeta);

Vue.config.productionTip = false

const routes = [
    {path: '/login', component: Login},
    {
        path: '/admin',
        component: Admin,
        meta: {
            requiresAuth: true
        },
        children: [
            {path: 'posts', component: AllPosts},
            {path: 'posts/edit/:postId', component: EditPost},
            {path: 'posts/create', component: EditPost},
            {path: '*', component: AllPosts},
        ]
    },
    {
        path: '/',
        component: StoreFront,
        children: [
            {path: 'newsletter', component: Post, props: {postId: 'newsletter'}},
            {path: 'privacy', component: Post, props: {postId: 'privacy'}},
            {path: 'about', component: Post, props: {postId: 'about'}},
            {path: 'posts', component: Posts},
            {path: 'posts/:postId', component: Post, props: true},
            {path: '*', component: Home}
        ]
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('jwt') == null) {
            next({
                path: '/login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next()
    }
})


Vue.use(VueGtag, {
    config: {
        id: process.env.NODE_ENV === 'production' ? "UA-127045540-1" : ''
    }
}, router);

Vue.prototype.$apiURI = process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:8181';

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    mounted() {
        // You'll need this for renderAfterDocumentEvent.
        document.dispatchEvent(new Event('render-event'))
    }
})
