<template>
    <div class="mb-3 flex items-center">
        <div class="mr-3 w-24">{{ title }}</div>
        <div :class="disabled ? 'border-grey-500' : 'border-teal-500'"
             class="w-full border-b border-b-2 py-2">
            <input :disabled="disabled"
                   class="appearance-none
                          w-full mr-3 py-1 px-2
                          bg-transparent text-gray-700 border-none leading-tight focus:outline-none"
                   type="text"
                   :placeholder="placeholder"
                   :aria-label="title"
                   v-bind:value="value"
                   v-on:input="$emit('input', $event.target.value)">
        </div>
    </div>
</template>

<script>
    export default {
        name: "InlineTextInput",
        props: {
            title: String,
            placeholder: String,
            value: String,
            disabled: Boolean
        }
    }
</script>

<style scoped>

</style>