<template>
    <footer class="bg-gray-300 pt-6 pb-3">
        <div class="flex justify-center">
            <div class="h-8 w-8 mx-3 text-gray-800">
                <a href="https://www.facebook.com/faimycrossstitch/" @click="tracking('facebook')">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2z"/>
                    </svg>
                </a>
            </div>
            <div class="h-8 w-8 mx-3 text-gray-800">
                <a href="https://www.youtube.com/channel/UC4CEohUVIX5eDDgxvBcSs2g" @click="tracking('youtube')">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M10 2.3C.172 2.3 0 3.174 0 10s.172 7.7 10 7.7 10-.874 10-7.7-.172-7.7-10-7.7zm3.205 8.034l-4.49 2.096c-.393.182-.715-.022-.715-.456V8.026c0-.433.322-.638.715-.456l4.49 2.096c.393.184.393.484 0 .668z"/>
                    </svg>
                </a>
            </div>
            <div class="h-8 w-8 mx-3 text-gray-800">
                <a href="https://www.instagram.com/faimyxstitch/" @click="tracking('instagram')">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                        <path d="M17.946 5.29a6.606 6.606 0 0 0-.418-2.185 4.412 4.412 0 0 0-1.039-1.594A4.412 4.412 0 0 0 14.895.472a6.606 6.606 0 0 0-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 0 0-2.185.418A4.412 4.412 0 0 0 1.51 1.511 4.412 4.412 0 0 0 .472 3.105a6.606 6.606 0 0 0-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 0 0 .418 2.185 4.412 4.412 0 0 0 1.039 1.594 4.411 4.411 0 0 0 1.594 1.039 6.606 6.606 0 0 0 2.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 0 0 2.185-.418 4.602 4.602 0 0 0 2.633-2.633 6.606 6.606 0 0 0 .418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 0 1-.31 1.67 2.98 2.98 0 0 1-1.708 1.709 4.979 4.979 0 0 1-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 0 1-1.67-.31 2.788 2.788 0 0 1-1.036-.673 2.788 2.788 0 0 1-.673-1.035 4.978 4.978 0 0 1-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 0 1 .31-1.67 2.788 2.788 0 0 1 .673-1.036 2.788 2.788 0 0 1 1.035-.673 4.979 4.979 0 0 1 1.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 0 1 1.67.31 2.788 2.788 0 0 1 1.036.673 2.788 2.788 0 0 1 .673 1.035 4.979 4.979 0 0 1 .31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1 0 13.622 9 4.622 4.622 0 0 0 9 4.378zM9 12a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm5.884-7.804a1.08 1.08 0 1 1-1.08-1.08 1.08 1.08 0 0 1 1.08 1.08z"/>
                    </svg>
                </a>
            </div>
            <div class="h-8 w-8 mx-3 text-gray-800">
                <a href="https://www.pinterest.ca/faimyxstitch/" @click="tracking('pinterest')">
                    <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M10 .4C4.698.4.4 4.698.4 10s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6S15.302.4 10 .4zm.657 11.875c-.616-.047-.874-.352-1.356-.644-.265 1.391-.589 2.725-1.549 3.422-.297-2.104.434-3.682.774-5.359-.579-.975.069-2.936 1.291-2.454 1.503.596-1.302 3.625.581 4.004 1.966.394 2.769-3.412 1.55-4.648-1.762-1.787-5.127-.041-4.713 2.517.1.625.747.815.258 1.678-1.127-.25-1.464-1.139-1.42-2.324.069-1.94 1.743-3.299 3.421-3.486 2.123-.236 4.115.779 4.391 2.777.309 2.254-.959 4.693-3.228 4.517z"/>
                    </svg>
                </a>
            </div>
        </div>
        <div class="text-center mt-1 font-prompt underline">
            <router-link class="hover:text-blue-600" to="/privacy">Privacy policy</router-link>
        </div>
        <div class="text-center mt-1 font-prompt">
            ©2020 @faimyxstitch
        </div>
    </footer>
</template>

<script>
    export default {
        name: "Footer",
        methods: {
            tracking: function (trackingId) {
                this.$gtag.event('generate_lead', {
                    'event_label': trackingId
                })
            }
        }
    }
</script>

<style scoped>

</style>