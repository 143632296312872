<template>
    <div class="flex justify-center items-center my-64">
        <div class="w-64">
            <form>
                <input type="hidden" name="u" value="c333b746effbc3e600cf5070c">
                <input type="hidden" name="id" value="e76ac9a749">
                <div class="relative mb-5">
                    <label class="hidden block text-gray-700 text-sm font-bold mb-2" for="username">
                        Username
                    </label>
                    <input class="shadow appearance-none rounded-none border w-full py-2 px-3 text-gray-700 font-prompt focus:outline-none focus:shadow-outline"
                           v-model="username" type="text" id="username" placeholder="Username"/>
                </div>
                <div class="relative mb-6">
                    <label class="hidden block text-gray-700 text-sm font-bold mb-2" for="password">
                        Password
                    </label>
                    <input class="shadow appearance-none rounded-none border w-full py-2 px-3 text-gray-700 font-prompt focus:outline-none focus:shadow-outline"
                           v-model="password" type="password" id="password" placeholder="Password"/>
                </div>
                <div class="flex justify-center">
                    <button class="bg-blue-500 uppercase hover:bg-opacity-75 text-normal text-white font-bold tracking-wide py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                            type="submit" @click="handleSubmit">
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: ""
            }
        },
        methods: {
            handleSubmit(e) {
                e.preventDefault()
                if (this.password.length > 0) {
                    this.$http.post(this.$apiURI + '/login', {
                        username: this.username,
                        password: this.password
                    })
                        .then(response => {
                            localStorage.setItem('jwt', response.data.token)

                            if (localStorage.getItem('jwt') != null) {
                                this.$emit('loggedIn')
                                if (this.$route.params.nextUrl != null) {
                                    this.$router.push(this.$route.params.nextUrl)
                                } else {
                                    this.$router.push('/admin/posts')
                                }
                            }
                        })
                        .catch(function (error) {
                            console.error(error.response);
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>