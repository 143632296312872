<template>
    <div>
        <LinkPanel :url="patreon.url"
                   :image-src="patreon.image"
                   :image-alt="patreon.imageAlt"
                   :text="patreon.text"
                   :color="patreon.color"
                   :tracking-id="patreon.trackingId"/>

        <LinkPanel :url="etsy.url"
                   :image-src="etsy.image"
                   :image-alt="etsy.imageAlt"
                   :text="etsy.text"
                   :color="etsy.color"
                   :tracking-id="etsy.trackingId"/>

        <LinkPanel :url="frames.url"
                   :image-src="frames.image"
                   :image-alt="frames.imageAlt"
                   :text="frames.text"
                   :color="frames.color"
                   :tracking-id="frames.trackingId"/>

        <LinkPanel :url="citiesCourse.url"
                   :image-src="citiesCourse.image"
                   :image-alt="citiesCourse.imageAlt"
                   :text="citiesCourse.text"
                   :color="citiesCourse.color"
                   :tracking-id="citiesCourse.trackingId"/>

        <LinkPanel :url="domestika.url"
                   :image-src="domestika.image"
                   :image-alt="domestika.imageAlt"
                   :text="domestika.text"
                   :color="domestika.color"
                   :tracking-id="domestika.trackingId"/>

        <LinkPanel :url="stitchesCourse.url"
                   :image-src="stitchesCourse.image"
                   :image-alt="stitchesCourse.imageAlt"
                   :text="stitchesCourse.text"
                   :color="stitchesCourse.color"
                   :tracking-id="stitchesCourse.trackingId"/>

        <LinkPanel :url="stitchAlong.url"
                   :image-src="stitchAlong.image"
                   :image-alt="stitchAlong.imageAlt"
                   :text="stitchAlong.text"
                   :color="stitchAlong.color"
                   :tracking-id="stitchAlong.trackingId"/>

        <LinkPanel :url="youtube.url"
                   :image-src="youtube.image"
                   :image-alt="youtube.imageAlt"
                   :text="youtube.text"
                   :color="youtube.color"
                   :tracking-id="youtube.trackingId"/>

        <LinkPanel :url="articles.url"
                   :image-src="articles.image"
                   :image-alt="articles.imageAlt"
                   :text="articles.text"
                   :color="articles.color"
                   :tracking-id="articles.trackingId"/>

        <QuickTipYoutube/>
    </div>
</template>

<script>
    import LinkPanel from "@/components/LinkPanel";
    import QuickTipYoutube from "@/components/QuickTipYoutube";

    export default {
        name: "HomeContent",
        components: {QuickTipYoutube, LinkPanel},
        data() {
            return {
                patreon: {
                  text: "Join community on Patreon",
                  image: require("@/assets/pages/home/backgrounds/patreon.jpeg"),
                  imageAlt: "Become a Patron!",
                  url: "https://www.patreon.com/bePatron?u=12771447",
                  color: 'patreon',
                  trackingId: 'patreon'
                },
                domestika: {
                  text: "Online course Upcycling with Embroidery",
                  image: require("@/assets/pages/home/backgrounds/domestika.jpeg"),
                  imageAlt: "Online course Upcycling with Embroidery",
                  url: "https://www.domestika.org/en/courses/1395-upcycling-with-embroidery/faimyxstitch",
                  color: 'green',
                  trackingId: 'domestika'
                },
                etsy: {
                    text: "Buy my patterns",
                    image: require("@/assets/pages/home/backgrounds/etsy.jpg"),
                    imageAlt: "Shop my patterns",
                    url: "https://faimyxstitch.etsy.com",
                    color: 'orange',
                    trackingId: 'etsy'
                },
                frames: {
                  text: "Buy display frames",
                  image: require("@/assets/pages/home/backgrounds/frames.jpeg"),
                  imageAlt: "Buy a display frame!",
                  url: "https://faimyxstitch.etsy.com/listing/1694372340",
                  color: 'blue',
                  trackingId: 'frames'
                },
                stitchesCourse: {
                    text: "Hand embroidery\n stitches and practice",
                    image: require("@/assets/pages/home/backgrounds/stitches_course.jpg"),
                    imageAlt: "Hand embroidery stitches and practice",
                    url: "https://faimyxstitch.thinkific.com/courses/hand-embroidery-stitches-and-practice",
                    color: 'violet',
                    trackingId: 'stitches_course'
                },
                citiesCourse: {
                    text: "Draw and embroider\n your city",
                    image: require("@/assets/pages/home/backgrounds/city_course.jpg"),
                    imageAlt: "Draw and embroider your city",
                    url: "https://faimyxstitch.thinkific.com/courses/how-to-draw-and-embroider-your-city",
                    color: 'pink',
                    trackingId: 'cities_course'
                },
                stitchAlong: {
                    text: "Sveti Stefan\n Stitch along",
                    image: require("@/assets/pages/home/backgrounds/stitch_along.jpg"),
                    imageAlt: "Sveti Stefan Stitch along",
                    url: "https://www.instagram.com/p/CAavKXOI9Vp/",
                    color: 'blue',
                    trackingId: 'stitch_along'
                },
                youtube: {
                    text: "Youtube channel",
                    image: require("@/assets/pages/home/backgrounds/youtube.jpg"),
                    imageAlt: "Youtube channel",
                    url: "https://www.youtube.com/channel/UC4CEohUVIX5eDDgxvBcSs2g",
                    color: 'red',
                    trackingId: 'youtube'
                },
                articles: {
                  text: "Articles about embroidery",
                  image: require("@/assets/pages/home/backgrounds/articles_1.jpg"),
                  imageAlt: "Articles about embroidery",
                  url: "/posts",
                  color: 'green',
                  trackingId: 'articles'
                }
            }
        },
        metaInfo: {
            title: 'Home',
            meta: [
                {
                    name: 'description', content: 'Faimyxstitch website about hand embroidery. City patterns, ' +
                        'architectural embroideries, video tutorials and articles with embroidery tips.'
                },
                {
                    name: 'keywords',
                    content: 'hand embroidery, video tutorial, city embroidery pattern, diy project, ' +
                        'craft ideas, architectural embroidery, hand embroidery tips, faimyxstitch, FaimyCross Stitch'
                }
            ]
        }
    }
</script>

<style scoped>

</style>