<template>
    <a :href="url" @click="tracking">
        <div class="relative h-40 flex items-center mb-3
                lg:h-64 lg:mb-1">
            <div class="z-0 h-full w-full">
                <img class="object-cover h-full w-full" :src="imageSrc" :alt="imageAlt">
            </div>
            <div class="absolute z-10 h-full w-full">
                <div class="px-6 py-3
                        md:text-center md:flex md:h-full md:justify-center md:items-center">
                        <span :class="bgColor + ' ' + shadow"
                              class="text-white text-3xl whitespace-pre-line
                                     md:whitespace-no-wrap
                                     lg:text-5xl">
                            {{ text }}
                        </span>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "LinkPanel",
        props: {
            imageSrc: String,
            imageAlt: String,
            url: String,
            text: String,
            color: String,
            trackingId: String
        },
        computed: {
            bgColor: function () {
                switch (this.color) {
                    case "violet":
                        return "bg-faimy-violet";
                    case "orange":
                        return "bg-faimy-orange";
                    case "pink":
                        return "bg-faimy-pink";
                    case "green":
                        return "bg-custom-green";
                    case "blue":
                        return "bg-faimy-blue";
                    case "red":
                        return "bg-faimy-red";
                    case "patreon":
                        return "bg-faimy-patreon";
                    default:
                        return "bg-white";
                }
            },
            shadow: function () {
                switch (this.color) {
                    case "violet":
                        return "shadow-text-violet";
                    case "orange":
                        return "shadow-text-orange";
                    case "pink":
                        return "shadow-text-pink";
                    case "green":
                        return "shadow-text-custom-green";
                    case "blue":
                        return "shadow-text-blue";
                    case "red":
                        return "shadow-text-red";
                    case "patreon":
                        return "shadow-text-patreon";
                    default:
                        return "shadow-text-white";
                }
            }
        },
        methods: {
            tracking: function () {
                this.$gtag.event('generate_lead', {
                    'event_label': this.trackingId
                })
            }
        }
    }
</script>

<style scoped>

</style>