<template>
    <div id="app" class="antialiased text-gray-900 bg-gray-100">
        <router-view/>
    </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style>
</style>
