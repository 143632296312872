<template>
    <div class="py-6 mb-1 mt-4
                md:py-10
                lg:py-12 lg:mb-0">
        <div class="text-center mb-4
                    lg:mb-6">
            <router-link to="/newsletter">
                <span class="py-1 shadow-text-green bg-faimy-green text-white text-3xl underline
                             lg:text-4xl">
                    Join my newsletter
                </span>
            </router-link>
        </div>
        <div class="flex justify-center">
            <form action="https://faimyxstitch.us8.list-manage.com/subscribe/post" method="POST"
                  @submit="handleSubmit"
                  class="w-64">
                <input type="hidden" name="u" value="c333b746effbc3e600cf5070c">
                <input type="hidden" name="id" value="e76ac9a749">
                <div class="relative mb-5">
                    <label class="hidden block text-gray-700 text-sm font-bold mb-2" for="fullname">
                        Name
                    </label>
                    <input class="shadow appearance-none rounded-none border w-full py-2 px-3 text-gray-700 font-prompt focus:outline-none focus:shadow-outline"
                           :class="!$v.name.required && $v.name.$error ? 'border-red-500' : ''"
                           type="text" id="fullname" placeholder="Name" name="MERGE1"
                           v-model.trim="$v.name.$model"/>
                    <div class="absolute text-red-500 text-xs italic" v-if="!$v.name.required && $v.name.$error">
                        Name is required
                    </div>
                </div>
                <div class="relative mb-6">
                    <label class="hidden block text-gray-700 text-sm font-bold mb-2" for="email">
                        Email
                    </label>
                    <input class="shadow appearance-none rounded-none border w-full py-2 px-3 text-gray-700 font-prompt focus:outline-none focus:shadow-outline"
                           type="email" id="email" placeholder="Email" name="MERGE0"
                           :class="(!$v.email.required || !$v.email.email)&& $v.email.$error ? 'border-red-500' : ''"
                           v-model.trim="$v.email.$model"/>
                    <div class="absolute text-red-500 text-xs italic" v-if="!$v.email.required && $v.email.$error">
                        Email is required
                    </div>
                    <div class="absolute text-red-500 text-xs italic" v-if="!$v.email.email && $v.email.$error">
                        Email is not correct
                    </div>
                </div>
                <div class="flex justify-center">
                    <button class="bg-blue-500 uppercase hover:bg-opacity-75 text-normal text-white font-bold tracking-wide py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                            type="submit">
                        Subscribe
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        name: "JoinNewsletter",
        methods: {
            handleSubmit: function (e) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    e.preventDefault()
                } else {
                    this.$gtag.event('joined_newsletter', {
                        'event_category': 'engagement'
                    })
                }
            },
        },
        data() {
            return {
                name: '',
                email: ''
            }
        },
        validations: {
            name: {
                required
            },
            email: {
                required,
                email
            }
        }
    }
</script>

<style scoped>

</style>