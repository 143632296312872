<template>
    <button @click.prevent="$emit('click')"
            :disabled="disabled"
            :class="disabled ? 'bg-gray-400 cursor-not-allowed' : ('bg-' + this.color + ' hover:bg-opacity-75')"
            class="w-1/3 mx-3 my-1
                    md:w-auto
                    shadow uppercase text-normal text-white font-bold tracking-wide py-2 px-4 focus:outline-none focus:shadow-outline
                    lg:text-lg"
            type="submit">
        {{ title }}
    </button>
</template>

<script>
    export default {
        name: "CustomButton",
        props: {
            title: String,
            color: String,
            disabled: Boolean
        }
    }
</script>

<style scoped>

</style>