<template>
    <div class="w-full mx-auto mb-3
                md:max-w-screen-md">
        <div class="bg-white shadow rounded-lg px-3 py-3 mb-4 mx-3">
            <div class="h-8 flex items-center">
                <div class="w-1/3"></div>
                <div class="w-1/3 text-center text-xl">
                    General
                </div>
                <div v-if="post.online" class="w-1/3 flex justify-end items-center ml-auto pr-2">
                    <span>Online</span>
                    <div class="w-3 h-3 ml-2 bg-custom-green rounded-full"></div>
                </div>
                <div v-if="!post.online" class="w-1/3 flex justify-end items-center ml-auto pr-2">
                    <span>Offline</span>
                    <div class="w-3 h-3 ml-2 bg-custom-red rounded-full"></div>
                </div>
            </div>

            <InlineTextInput v-model="post.name" title="Name" placeholder="Post name"/>
            <InlineTextInput v-model="post.urlKey" title="Url path" placeholder="Url path"/>
            <InlineLargeTextInput v-model="post.description" title="Description" placeholder="Post description"/>
            <InlineImageUploadInput v-model="post.previewImage" title="Preview Image"/>
            <ImageBlock v-if="post.previewImage" class="mb-3 w-2/3 mx-auto" :src="post.previewImage"/>
            <InlineBooleanInput v-model="post.published" title="Published"/>
            <InlineBooleanInput v-model="post.standalone" title="Standalone"/>
            <InlineTextInput v-model="post.onlineFrom" title="Online From" placeholder="Post online from date"/>
            <InlineTextInput v-model="post.id" title="ID" placeholder="Post id" disabled/>
            <InlineTextInput v-model="post.createdAt" title="Created" placeholder="Post created date" disabled/>
            <InlineTextInput v-model="post.updatedAt" title="Updated" placeholder="Post last updated date" disabled/>
        </div>

        <div class="bg-white shadow rounded-lg px-3 py-3 mb-4 mx-3">
            <div class="text-center text-xl mb-3">
                Meta information
            </div>
            <InlineTextInput v-model="post.meta.title" title="Title" placeholder="Meta title"/>
            <InlineLargeTextInput v-model="post.meta.description" title="Description" placeholder="Meta description"/>
            <InlineLargeTextInput v-model="post.meta.keywords" title="Keywords" placeholder="Meta keywords"/>
        </div>

        <div class="text-center text-xl mb-3">
            Blocks
        </div>

        <div class="bg-white shadow rounded-lg px-3 py-3 mb-4 mx-3"
             v-for="(block, blockIndex) in post.blocks" :key="blockIndex">
            <div class="h-8 flex items-center">
                <div class="w-1/3"></div>
                <div class="w-1/3 text-center text-xl">
                    {{ getBlockTitle(block.__typename) }}
                </div>
                <div class="w-1/3 flex justify-end items-center ml-auto pr-2 text-2xl text-gray-800 focus:outline-none">
                    <ArrowUpButton v-if="blockIndex > 0"
                                   @click="moveBlockUp(blockIndex)"/>
                    <ArrowDownButton v-if="blockIndex < (post.blocks.length - 1)"
                                     @click="moveBlockDown(blockIndex)"/>
                    <CrossButton @click="deleteBlock(blockIndex)"/>
                </div>
            </div>

            <div v-if="block.__typename === 'title'">
                <InlineTextInput v-model="block.title" title="Title" placeholder="Title"/>
                <InlineColorSelect v-model="block.titleColor" title="Title Color"/>

                <div class="mb-8">
                    <PostTitle :title="block.title" :color="block.titleColor"/>
                </div>
            </div>

            <div v-if="block.__typename === 'text'">
                <InlineLargeTextInput v-model="block.text" title="Text" placeholder="Text"/>
                <InlineTextInput v-model="block.title" title="Title" placeholder="Title"/>
                <InlineColorSelect v-model="block.titleColor" title="Title color"
                                   placeholder="Title color"/>
                <InlineColorSelect v-model="block.bgColor" title="Background color"
                                   placeholder="Background color"/>

                <div class="mb-8">
                    <TextBlock :text="block.text"
                               :title="block.title"
                               :title-color="block.titleColor"
                               :bg-color="block.bgColor"/>
                </div>
            </div>

            <div v-if="block.__typename === 'list'">
                <InlineTextInput v-model="block.title" title="Title" placeholder="List title"/>
                <InlineColorSelect v-model="block.titleColor" title="Title Color"/>
                <InlineColorSelect v-model="block.markersColor" title="Markers Color"/>

                <div class="text-center text-lg mb-3">
                    Items
                </div>
                <div v-for="(listItem, listItemIndex) in block.items" :key="listItemIndex"
                     class="my-4 shadow bg-gray-100 rounded-lg px-3 py-3">
                    <div v-for="(itemNode, itemNodeIndex) in listItem.nodes" :key="itemNodeIndex"
                         class="my-3">
                        <div class="flex">
                            <div class="w-1/3"></div>
                            <div v-if="itemNode.__typename === 'list-item-text'" class="w-1/3 text-center">Text</div>
                            <div v-if="itemNode.__typename === 'list-item-image'" class="w-1/3 text-center">Image</div>
                            <div v-if="itemNode.__typename === 'list-item-youtube-video'" class="w-1/3 text-center">Youtube video</div>
                            <div class="w-1/3 flex justify-end items-center ml-auto pr-2 text-2xl text-gray-800 focus:outline-none">
                                <ArrowUpButton v-if="itemNodeIndex > 0"
                                               @click="moveListItemNodeUp(blockIndex, listItemIndex, itemNodeIndex)"/>
                                <ArrowDownButton v-if="itemNodeIndex < (listItem.nodes.length - 1)"
                                                 @click="moveListItemNodeDown(blockIndex, listItemIndex, itemNodeIndex)"/>
                                <CrossButton @click="deleteListItemNode(blockIndex, listItemIndex, itemNodeIndex)"/>
                            </div>
                        </div>

                        <div v-if="itemNode.__typename === 'list-item-text'">
                            <InlineLargeTextInput v-model="itemNode.text" title="Text" placeholder="List node text"/>
                        </div>

                        <div v-if="itemNode.__typename === 'list-item-image'">
                            <div class="text-lg mb-3">
                                <InlineImageUploadInput v-model="itemNode.src" title="Select file"/>
                                <InlineTextInput v-model="itemNode.alt" title="Alt" placeholder="Image alt"/>
                                <InlineTextInput v-model="itemNode.caption" title="Caption" placeholder="Image caption"/>
                                <InlineTextInput v-model="itemNode.src" title="Src" placeholder="List node src" disabled/>
                            </div>
                        </div>

                        <div v-if="itemNode.__typename === 'list-item-youtube-video'">
                            <div class="text-lg mb-3">
                                <InlineTextInput v-model="itemNode.videoId" title="Video ID" placeholder="Youtube Video ID"/>
                                <InlineTextInput v-model="itemNode.caption" title="Caption" placeholder="Video Caption"/>
                            </div>
                        </div>
                        <div class="mt-8">
                            <hr/>
                        </div>
                    </div>

                    <div class="flex">
                        <button @click.prevent="addListItemNode(blockIndex, listItemIndex, 'list-item-text')"
                                class="mx-1 underline text-normal py-2 px-4 focus:outline-none focus:shadow-outline
                                       lg:text-lg"
                                type="submit">
                            Add Text
                        </button>
                        <button @click.prevent="addListItemNode(blockIndex, listItemIndex,'list-item-image')"
                                class="mx-1 underline text-normal py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                                type="submit">
                            Add Image
                        </button>
                        <button @click.prevent="addListItemNode(blockIndex, listItemIndex,'list-item-youtube-video')"
                                class="mx-1 underline text-normal py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                                type="submit">
                            Add Video
                        </button>
                        <div class="w-1/3 flex justify-end items-center ml-auto pr-2 text-2xl text-gray-800 focus:outline-none">
                            <ArrowUpButton v-if="listItemIndex > 0" @click="moveListItemUp(blockIndex, listItemIndex)"/>
                            <ArrowDownButton v-if="listItemIndex < (block.items.length - 1)"
                                             @click="moveListItemDown(blockIndex, listItemIndex)"/>
                            <CrossButton @click="deleteListItem(blockIndex, listItemIndex)"/>
                        </div>
                    </div>
                </div>

                <div>
                    <button @click.prevent="addListItem(blockIndex)"
                            class="mx-1 underline text-normal py-2 px-4 focus:outline-none focus:shadow-outline
                                   lg:text-lg"
                            type="submit">
                        Add Item
                    </button>
                </div>

                <div class="mb-6 mt-8">
                    <List :title="block.title"
                          :title-color="block.titleColor"
                          :markers-color="block.markersColor"
                          :items="block.items"/>
                </div>
            </div>

            <div v-if="block.__typename === 'image'">
                <InlineImageUploadInput v-model="block.src" title="Select image"/>
                <InlineTextInput v-model="block.alt" title="Alt" placeholder="Image alt"/>
                <InlineTextInput v-model="block.caption" title="Caption" placeholder="Image caption"/>
                <InlineTextInput v-model="block.src" title="Source" placeholder="Image source" disabled/>

                <div class="mb-6 mt-8">
                    <ImageBlock :src="block.src"
                                :alt="block.alt"
                                :caption="block.caption"/>
                </div>
            </div>

            <div v-if="block.__typename === 'youtube-video'">
                <InlineTextInput v-model="block.videoId" title="Youtube video id"/>
                <InlineTextInput v-model="block.caption" title="Caption" placeholder="Video caption"/>

                <div class="mb-6 mt-8">
                    <YoutubeVideoBlock :video-id="block.videoId"
                                       :caption="block.caption"/>
                </div>
            </div>

            <div v-if="block.__typename === 'button-link'">
                <InlineTextInput v-model="block.text" title="Title" placeholder="Button text"/>
                <InlineTextInput v-model="block.url" title="Url" placeholder="Button link"/>
                <InlineColorSelect v-model="block.color" title="Color"/>

                <div class="mb-6 mt-8">
                    <ButtonLink :color="block.color"
                                :text="block.text"
                                :url="block.url"/>
                </div>
            </div>
        </div>

        <div class="flex justify-center flex-wrap my-3">
            <CustomButton title="Add Text" @click="addBlock('text')" color="custom-blue"/>
            <CustomButton title="Add Title" @click="addBlock('title')" color="custom-blue"/>
            <CustomButton title="Add Image" @click="addBlock('image')" color="custom-blue"/>
            <CustomButton title="Add Video" @click="addBlock('youtube-video')" color="custom-blue"/>
            <CustomButton title="Add List" @click="addBlock('list')" color="custom-blue"/>
            <CustomButton title="Add Btn" @click="addBlock('button-link')" color="custom-blue"/>
        </div>

        <div class="flex justify-center">
            <CustomButton title="Save"
                          @click="savePost"
                          color="custom-green"
                          :disabled="state === 'SAVING'"
            />
        </div>
    </div>
</template>

<script>
    import InlineTextInput from "@/components/admin/InlineTextInput";
    import InlineColorSelect from "@/components/admin/InlineColorSelect";
    import PostTitle from "@/components/post/PostTitle";
    import TextBlock from "@/components/post/TextBlock";
    import ButtonLink from "@/components/post/ButtonLink";
    import List from "@/components/post/List";
    import ImageBlock from "@/components/post/ImageBlock";
    import InlineBooleanInput from "@/components/admin/InlineBooleanInput";
    import InlineImageUploadInput from "@/components/admin/InlineImageUploadInput";
    import CrossButton from "@/components/admin/CrossButton";
    import ArrowUpButton from "@/components/admin/ArrowUpButton";
    import ArrowDownButton from "@/components/admin/ArrowDownButton";
    import CustomButton from "@/components/admin/CustomButton";
    import InlineLargeTextInput from "@/components/admin/InlineLargeTextInput";
    import YoutubeVideoBlock from "@/components/post/YoutubeVideoBlock";

    const axios = require('axios').default
    const apiUri = process.env.NODE_ENV === 'production' ? 'https://faimyxstitch.com/api' : 'http://localhost:8181'

    function getPost(postId, callback) {
        const headers = !localStorage.jwt ? {} : {
            Authorization: 'Bearer ' + localStorage.jwt
        }

        axios
            .get(apiUri + '/posts/' + postId, {
                headers: headers
            })
            .then((response) => {
                callback(response.data)
            })
    }

    export default {
        name: "EditPost",
        components: {
            YoutubeVideoBlock,
            InlineLargeTextInput,
            CustomButton,
            ArrowDownButton,
            ArrowUpButton,
            CrossButton,
            InlineImageUploadInput,
            InlineBooleanInput,
            ImageBlock, List, ButtonLink, TextBlock, PostTitle, InlineColorSelect, InlineTextInput
        },
        data() {
            return {
                state: "EDITING",
                post: {
                    meta: {},
                    blocks: []
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            const postId = to.params.postId
            if (postId) {
                getPost(postId, (post) => {
                    next(vm => vm.setData(post));
                });
            } else {
                // New post
                next(vm => vm.post = {
                    meta: {},
                    blocks: []
                })
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.post = {
                meta: {},
                blocks: []
            }
            const postId = to.params.postId
            getPost(postId, (post) => {
                next(vm => vm.setData(post));
            });
        },
        methods: {
            setData(post) {
                this.post = post
            },
            savePost: function () {
                const toastedOptions = {
                    position: "top-center",
                    duration: 3000,
                }

                const postUrlKey = this.$route.params.postId ? this.$route.params.postId : ''

                this.state = "SAVING"

                this.$http
                    .post(this.$apiURI + '/posts/' + postUrlKey, this.post, {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.jwt
                        }
                    })
                    .then(response => {
                        this.state = "EDITING"

                        this.$toasted.success('Success!', toastedOptions);

                        if (postUrlKey === '') {
                            this.$router.push('/admin/posts/edit/' + response.data.urlKey)
                        } else {
                            this.post = response.data
                        }
                    })
                    .catch(error => {
                        this.state = "EDITING"

                        if (error.response) {
                            this.$toasted.error('Error occurred!', toastedOptions);

                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        }
                    });
            },
            addListItem: function (listBlockIdx) {
                if (!this.post.blocks[listBlockIdx].items) {
                    this.$set(this.post.blocks[listBlockIdx], 'items', [])
                }

                this.post.blocks[listBlockIdx].items.push({
                    nodes: []
                })
            },
            deleteListItem: function (blockIdx, listItemIdx) {
                this.post.blocks[blockIdx].items.splice(listItemIdx, 1)
            },
            moveListItemUp: function (blockIdx, listItemIdx) {
                let listItems = this.post.blocks[blockIdx].items
                if (listItemIdx === 0) {
                    return
                }

                listItems.splice(listItemIdx - 1, 2, listItems[listItemIdx], listItems[listItemIdx - 1])
            },
            moveListItemDown: function (blockIdx, listItemIdx) {
                let listItems = this.post.blocks[blockIdx].items
                if (listItemIdx === (listItems.length - 1)) {
                    return
                }

                listItems.splice(listItemIdx, 2, listItems[listItemIdx + 1], listItems[listItemIdx])
            },
            addListItemNode: function (listBlockIdx, listItemIdx, typename) {
                this.post.blocks[listBlockIdx].items[listItemIdx].nodes.push({
                    __typename: typename
                })
            },
            deleteListItemNode: function (blockIdx, listItemIdx, listItemNodeIdx) {
                this.post.blocks[blockIdx].items[listItemIdx].nodes.splice(listItemNodeIdx, 1)
            },
            moveListItemNodeUp: function (blockIdx, listItemIdx, listItemNodeIdx) {
                let listItemNodes = this.post.blocks[blockIdx].items[listItemIdx].nodes
                if (listItemNodeIdx === 0) {
                    return
                }

                listItemNodes.splice(listItemNodeIdx - 1, 2, listItemNodes[listItemNodeIdx], listItemNodes[listItemNodeIdx - 1])
            },
            moveListItemNodeDown: function (blockIdx, listItemIdx, listItemNodeIdx) {
                let listItemNodes = this.post.blocks[blockIdx].items[listItemIdx].nodes
                if (listItemNodeIdx === (listItemNodes.length - 1)) {
                    return
                }

                listItemNodes.splice(listItemNodeIdx, 2, listItemNodes[listItemNodeIdx + 1], listItemNodes[listItemNodeIdx])
            },
            addBlock: function (blockType) {
                this.post.blocks.push({
                    __typename: blockType
                })
            },
            deleteBlock: function (blockIndex) {
                this.post.blocks.splice(blockIndex, 1)
            },
            moveBlockUp: function (blockIndex) {
                let blocks = this.post.blocks
                if (blockIndex === 0) {
                    return
                }

                blocks.splice(blockIndex - 1, 2, blocks[blockIndex], blocks[blockIndex - 1])
            },
            moveBlockDown: function (blockIndex) {
                let blocks = this.post.blocks
                if (blockIndex === (blocks.length - 1)) {
                    return
                }

                blocks.splice(blockIndex, 2, blocks[blockIndex + 1], blocks[blockIndex])
            },
            getBlockTitle: function (blockType) {
                switch (blockType) {
                    case 'text':
                        return 'Text'
                    case 'title':
                        return 'Title'
                    case 'list':
                        return 'List'
                    case 'image':
                        return 'Image'
                    case 'youtube-video':
                        return 'Youtube Video'
                    case 'button-link':
                        return 'Button Link'
                    default:
                        return 'Unknown'
                }
            }
        }
    }
</script>

<style scoped>

</style>