<template>
    <svg @click="$emit('click')"
         viewBox="0 0 20 20"
         class="h-4 w-4 mx-1 fill-current cursor-pointer">
        <path d="M10,19.25L4.5,14H8V1h4v13h3.5L10,19.25z"/>
    </svg>
</template>

<script>
    export default {
        name: "ArrowDownButton",
    }
</script>

<style scoped>

</style>