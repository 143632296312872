<template>
    <div :class="(bgColor ? ('bg-' + bgColor) : '') + ' ' + (title ? 'py-4' : 'py-2')"
         class="px-6 text-lg
                md:mt-8">
        <span v-if="title"
              :class="'bg-' + titleColor + ' ' + 'shadow-text-' + titleColor"
              class="ml-2 font-thin text-white text-2xl whitespace-pre-line
                     md:whitespace-no-wrap
                     lg:text-4xl">
                {{ title }}
            </span>
        <div class="text-block text-lg md:mt-2 whitespace-pre-wrap" v-html="preprocessText(text)"></div>
    </div>
</template>

<script>
    import htmlUtils from '@/util/html_utils';

    export default {
        name: "TextBlock",
        props: {
            title: String,
            titleColor: String,
            bgColor: String,
            text: String
        },
        mixins: [htmlUtils]
    }
</script>

<style scoped>
    .text-block >>> a {
        @apply underline text-custom-blue
    }
</style>