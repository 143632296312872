<template>
  <div @click="$emit('click')">
    <router-link v-if="to"
                 :class="secondary ?
                        'font-light md:my-2 md:py-1 md:text-sm md:text-left md:text-gray-800 lg:text-base' :
                        'font-semibold md:text-base md:mt-0 lg:text-lg'"
                 class="block
                    font-sans uppercase tracking-wider
                    mt-1 px-2 py-1 text-center rounded hover:bg-gray-300
                    md:py-0 md:px-4 md:font-medium md:hover:bg-gray-100 md:underline
                    lg:px-6 lg:font-normal
                 "
                 :to="to">
      <slot/>
    </router-link>
    <a v-if="url"
       :class="secondary ?
                        'font-light md:my-2 md:py-1 md:text-sm md:text-left md:text-gray-800 lg:text-base' :
                        'font-semibold md:text-base md:mt-0 lg:text-lg'"
       class="block
                    font-sans uppercase tracking-wider
                    mt-1 px-2 py-1 text-center rounded hover:bg-gray-300
                    md:py-0 md:px-4 md:font-medium md:hover:bg-gray-100 md:underline
                    lg:px-6 lg:font-normal
                 "
       :href="url">
      <slot/>
    </a>
  </div>
</template>

<script>
export default {
  name: "NavBarLink",
  props: {
    to: String,
    url: String,
    secondary: Boolean
  }
}
</script>

<style scoped>

</style>